// 安装说明
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import installIcon1 from "../assets/images/installIcon1.svg";
import installIcon2 from "../assets/images/installIcon2.png";
import installIcon3 from "../assets/images/installIcon3.png";

export const GetStart = React.forwardRef(({handleClickConnect}, ref) => {
    const { t } = useTranslation();

    const handleConnect = () => {
        handleClickConnect()
    }

    return (
        <div className="get-start-comp">
            <div className="start-item-box">
                <div className="icon-box">
                    <img src={installIcon1} className="install-icon icon-1"></img>
                </div>
                <div className="info-box">
                    <div className="title">{t('selectWallet.openTitle')}</div>
                    <div className="desc">{t('selectWallet.openDesc')}</div>
                </div>
            </div>
            <div className="start-item-box">
                <div className="icon-box">
                <img src={installIcon2} className="install-icon icon-2"></img>
                </div>
                <div className="info-box">
                    <div className="title">{t('selectWallet.createTitle')}</div>
                    <div className="desc">{t('selectWallet.createDesc')}</div>
                </div>

            </div>
            <div className="start-item-box">
                <div className="icon-box">
                <img src={installIcon3} className="install-icon icon-3"></img>


                </div>
                <div className="info-box">
                    <div className="title">{t('selectWallet.scanTitle2')}</div>
                    <div className="desc">{t('selectWallet.scanDesc')}</div>
                </div>

            </div>

            <Button className="connect-btn" onClick={()=> handleConnect()}>{t('connectWallet.connect')}</Button>
        </div>
    )
})