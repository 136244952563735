import React, { useRef, useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import LanguageIcon from "../assets/images/language.svg";
import QrCodeIcon from "../assets/images/qrCode.svg";
import { Menu, Dropdown } from "antd";
import { WalletApp } from "../components/WalletApp";
import { ConnectWalletModal } from "../components/ConnectWalletModal";
import LogoImg from "../assets/images/home/img4.png";
import enIcon from "../assets/images/en.png";
import zhIcon from "../assets/images/zh.png";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import siderLogoIcon from "../assets/images/dapp/siderLogo.svg";
import { ConnectNetError } from "../components/connectNetError";
import { PermissionReminder } from "../components/permissionReminder";
import useGlobal from "../utils/globalHooks";
import { AccountCornerInfo } from "../components/AccountCornerInfo";
const items = [
  {
    label: (
      <NavLink to="/home">
        <span className="nav-text">Home</span>
      </NavLink>
    ),
    key: "home",
  },
  {
    label: <span className="nav-text">Ecosystem</span>,
    key: "ecosystem",
    popupClassName: "ecosystem-submenu",
    children: [
      {
        label: (
          <NavLink to="/ecosystem/index">
            <span className="nav-text">Ecology</span>
          </NavLink>
        ),
        key: "ecosystemIndex",
      },
      {
        label: (
          <NavLink to="/ecosystem/esdtToken">
            <span className="nav-text">Esdt Token</span>
          </NavLink>
        ),
        key: "esdtToken",
      },
      {
        label: (
          <NavLink to="/ecosystem/ltToken">
            <span className="nav-text">LT Token</span>
          </NavLink>
        ),
        key: "ltToken",
      },
    ],
  },
  {
    label: (
      <NavLink to="/community">
        <span className="nav-text">Community</span>
      </NavLink>
    ),
    key: "community",
  },
  {
    label: (
      <NavLink to="/faq">
        <span className="nav-text">FAQ</span>
      </NavLink>
    ),
    key: "faq",
  },
];
function PageHeader() {
  const history = useHistory();
  const walletAppRef = useRef();
  const connectNetErrorRef = useRef();
  const connectWalletModalRef = useRef();
  const accountCornerInfoRef = useRef();
  const [visible, setVisible] = useState(false);

  const [connectWalletVisible, setConnectWalletVisible] = useState(false);
  const { t } = useTranslation();
  const [locale, setLocale] = React.useState("");
  const [current, setCurrent] = useState("home");
  let location = useLocation();

  const [globalState, setGlobalState] = useGlobal();

  useEffect(() => {
    if (location.pathname.indexOf("/dapp") > -1) {
      setCurrent("dapp");
    } else if (location.pathname.indexOf("/home") > -1) {
      setCurrent("home");
    } else if (location.pathname.indexOf("/ecosystem") > -1) {
      setCurrent("ecosystem");
    } else if (location.pathname.indexOf("/community") > -1) {
      setCurrent("community");
    } else if (location.pathname.indexOf("/faq") > -1) {
      setCurrent("faq");
    }

    // 判断浏览器cookie收集权限
    console.log("cookie>>>>>", navigator.cookieEnabled);
  }, [location]);
  useEffect(() => {
    if (sessionStorage.getItem("langType")) {
      setLocale(sessionStorage.getItem("langType") || "en-US"); // 设置默认语言为浏览器的语言
    } else {
      setLocale("en-US"); // 如果无法获取到浏览器语言则默认为英文
    }
    return () => {}; // 清除effects时不进行任何操作
  }, []);

  const launchDapp = () => {
    setCurrent("dapp");
    history.push("/dapp/indexOverview");
  };

  // 点击显示wallet app
  const handleShowWalletApp = () => {
    walletAppRef.current.openModal();
  };

  const handleLogoClick = () => {
    history.push("/home");
  };

  const handleCloseConnectWallet = () => {
    setConnectWalletVisible(false);
  };

  const handleSetlanguage = (lang) => {
    sessionStorage.setItem("langType", lang);
    i18n.changeLanguage(lang);
    setLocale(lang);
  };
  const [btnValue, setBtnValue] = useState(
    sessionStorage.getItem("deviceType")
  );
  const [addrValue, setAddrValue] = useState(
    sessionStorage.getItem("addr")
      ? sessionStorage.getItem("addr")
      : "+ Connect Wallet"
  );
  const onClickMenu = (e) => {
    setCurrent(e.key);
  };
  const languageItems = [
    {
      key: "2",
      label: (
        <div
          className={
            locale == "en-US" ? "language-item-selected" : "language-item"
          }
          onClick={() => {
            handleSetlanguage("en-US");
          }}
        >
          <img src={enIcon} className="language-icon" />
          <span className="language-text">{t("home.en")}</span>
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <div
          className={
            locale == "zh-CN" ? "language-item-selected" : "language-item"
          }
          onClick={() => {
            handleSetlanguage("zh-CN");
          }}
        >
          <img src={zhIcon} className="language-icon" />
          <span className="language-text">{t("home.ch")}</span>
        </div>
      ),
    },
  ];

  const onStorage = useRef(() => {});

  // useEffect(() => {
  //   onStorage.current = () => {
  //     const addr = sessionStorage.getItem("addr");
  //     setAddrValue(addr);
  //     console.log('Session storage changed!');
  //     这里可以根据需要进行相应的处理
  //   };

  //   window.addEventListener('addr', onStorage.current);

  // }, [addrValue]);

  useEffect(() => {
    const addr = sessionStorage.getItem("addr");
    console.log(addr);
    if (addr) {
      setAddrValue(addr);
    }
    // 需要最新data后的操作
  }, addrValue);
  const handleConnectWallet = () => {
    const addr = sessionStorage.getItem("addr");
    console.log("window.navigator.onLine=======", window.navigator.onLine);
    if (window.navigator.onLine && !addr) {
      connectWalletModalRef.current.open();
    } else if (!addr) {
      connectNetErrorRef.current.open();
    }
  };

  // 点击显示用户信息
  const handleShowAccountCornerInfo = () => {
    accountCornerInfoRef.current.open();
  };
  return (
    <div className="page-header">
      {/* <h2 style={{color:'#fff'}}>{globalState.accountInfo}</h2> */}
      <div className="header-container">
        <div className="logo-box">
          <div className="logo">
            <img src={siderLogoIcon} onClick={() => handleLogoClick()} />
          </div>
          {/* <div className="logo-text">EASEDEX</div> */}
          <div
            className="logo-tag"
            onClick={() => handleShowAccountCornerInfo()}
          >
            BSC
          </div>
        </div>
        <div className="menu-box">
          <Menu
            mode="horizontal"
            selectedKeys={[current]}
            className="header-menu"
            items={items}
            onClick={onClickMenu}
            popupClassName="header-sub-menu"
          />

          <div className="header-actions">
            <div className="language-setting">
              <Dropdown
                menu={{ items: languageItems }}
                overlayClassName="pc-language-dropdown"
                placement="bottom"
              >
                <img src={LanguageIcon} />
              </Dropdown>
            </div>
            <div
              className="qrcode-setting"
              onClick={() => handleShowWalletApp()}
            >
              <img src={QrCodeIcon} />
              <WalletApp ref={walletAppRef} />
            </div>
            <div
              className={
                current == "dapp" ? "login-action-active" : "login-action"
              }
              onClick={() => launchDapp()}
            >
              Launch Dapp
            </div>
            <div className="wallet-action">
              <span onClick={() => handleConnectWallet()}>
                {addrValue == "" ? "" : addrValue}
              </span>
              <ConnectWalletModal
                ref={connectWalletModalRef}
                closeModal={() => {
                  handleCloseConnectWallet();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ConnectNetError ref={connectNetErrorRef} />
      <PermissionReminder />
      <AccountCornerInfo ref={accountCornerInfoRef} />
    </div>
  );
}

export default PageHeader;
