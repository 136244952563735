// 钱包登录主界面，扫码或者获取app
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { SelectWalletScan } from "./SelectWalletScan";
import metaMaskInco from "../assets/images/wallet/metaMask.svg";
import walletConnectIcon from "../assets/images/wallet/walletConnect.svg";
import walletConnect2Icon from "../assets/images/wallet/walletConnect2.svg";
import okxWalletIcon from "../assets/images/wallet/okxWallet.svg";
import bitKeepWalletIcon from "../assets/images/wallet/bitKeepWallet.svg";
import tokenPocketIcon from "../assets/images/wallet/tokenPocket.svg";
import mathWalletIcon from "../assets/images/wallet/mathWallet.svg";
import trustWalletIcon from "../assets/images/wallet/trustWallet.svg";

export const SelectWallet = React.forwardRef(
  ({ handleClose, handleConnect, handleWalletChange }, ref) => {
    const { t } = useTranslation();
    const [showScan, setShowScan] = useState(false);

    const [walletList, setWalletList] = useState([
      { name: "Meta Mask", icon: metaMaskInco },
      { name: "Wallet Connect", icon: walletConnectIcon },
      { name: "Wallet Connect 2", icon: walletConnect2Icon },
      { name: "OKX Wallet", icon: okxWalletIcon },
      { name: "BitKeep Wallet", icon: bitKeepWalletIcon },
      { name: "Token Pocket", icon: tokenPocketIcon },
      { name: "Math Wallet", icon: mathWalletIcon },
      { name: "Trust Wallet", icon: trustWalletIcon },
    ]);

    // 点击connect
    const handleClickConnect = () => {
      handleConnect();
    };

    const handleClickGetapp = () => {
      setShowScan(true);
    };

    const walletChange = () => {
      setShowScan(false);
    };
    return (
      <div className="select-wallet-comp">
        <div className="header">
          <div className="name">
            {showScan ? t("selectWallet.title") : t("selectWallet.getTitle")}
          </div>
          <CloseOutlined className="close-icon" onClick={() => handleClose()} />
        </div>
        <div className="body-box">
          {showScan ? (
            <SelectWalletScan
              handleBackList={() => {
                setShowScan(false);
              }}
              handleConnect={() => {
                handleClickConnect();
              }}
              handleChangeWallet={() => {
                walletChange();
              }}
            />
          ) : (
            <div className="left">
              <div className="group">
                <div className="agree-row">
                  <div className="agree-box">
                    <Checkbox></Checkbox>
                  </div>
                  <div className="agree-text">
                    I have read, understand, and agree to the
                    <span className="link-text">Terms of Service</span> and{" "}
                    <span className="link-text">Privacy Policy</span>.
                  </div>
                </div>
                {walletList.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="item-left">
                        <div className="item-icon">
                          <img src={item.icon} alt="icon" />
                        </div>
                        <div className="item-name">{item.name}</div>
                      </div>
                      <div className="item-right">
                        <div
                          className="get-app-btn"
                          onClick={() => {
                            handleClickGetapp();
                          }}
                        >
                          {t("selectWallet.getApp")}
                        </div>
                        <Button
                          className="connect-btn"
                          onClick={() => {
                            handleClickConnect();
                          }}
                        >
                          {t("selectWallet.connect")}
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
