import React from "react";
import { useTranslation } from "react-i18next";

import Logo from "../assets/images/home/img4.png";
import NameIcon from "../assets/images/home/img5.png";

const PageFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="h5-page-footer-comp">
      <div className="footer-top">
        <div className="footer-top-left">
          <div className="logo-box">
            <img className="logo" src={Logo} />
            <img className="name" src={NameIcon} />
            <div className="tag">BSC</div>
          </div>
          <div className="button-row">

          <div className="button-item">{t("footer.policy")}</div>
          <div className="button-item">{t("footer.term")}</div>


          </div>
        </div>
        <div className="footer-top-right">
            <div className="right-item-row">
                <div className="item-box">{t("footer.docs")}</div>
                <div className="item-box">{t("footer.touch")}</div>
            </div>
            <div className="right-item-row">
                <div className="item-box">{t("footer.assets")}</div>
                <div className="item-box">{t("footer.audits")}</div>
            </div>
            <div className="right-item-row">
                <div className="item-box">{t("footer.github")}</div>
                <div className="item-box"></div>

            </div>
        </div>
      </div>
      <div className="footer-bottom">{t("footer.msg")}</div>
      <div className="footer-link">www.easedex.finance</div>
    </div>
  );
};

export default PageFooter;
