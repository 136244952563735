import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";
import i18n from "i18next";

import { useHistory } from "react-router-dom";
import EnIcon from "../assets/images/en.png";
import zhIcon from "../assets/images/zh.png";
import yellowDownIcon from "../assets/images/h5/menu/yellowDown.png";
import rightWhiteIcon from "../assets/images/h5/menu/rightWhite.png";
import MenuHomeIcon from "../assets/images/h5/menu/menu_home.png";
import MenuEcosystemIcon from "../assets/images/h5/menu/menu_ecosystem.png";
import MenuCommunityIcon from "../assets/images/h5/menu/menu_community.png";
import MenuFaqIcon from "../assets/images/h5/menu/menu_faq.png";
import EcologyIcon from "../assets/images/h5/menu/menu_ecology.png";
import EsdtTokenIcon from "../assets/images/h5/menu/menu_esdttoken.png";
import LtTokenIcon from "../assets/images/h5/menu/menu_lttoken.png";


export const H5IndexMenu = React.forwardRef(({ handleColseMenu }, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [subMenuOpenStatus, setSubMenuOpenStatus] = useState(false);
  const [locale, setLocale] = React.useState('');

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    {
      key: 1,
      icon: null,
      children: [],
      label: (
        <div
          className="h5-index-menu-item"
          onClick={() => handleGoMenu("/home")}
        >
          <span className="nav-text">Home</span>
        </div>
      ),
    },
    {
      key: 2,
      icon: null,

      label: (
        <div className="h5-index-menu-item">
          <span className="nav-text">Ecosystem</span>
        </div>
      ),

      // children: [
      //   {
      //     key: 21,
      //     icon: null,
      //     label: (
      //       <div
      //         className="h5-index-menu-item"
      //         onClick={() => handleGoMenu("/ecosystem/index")}
      //       >
      //         <span className="nav-text">Ecosystem</span>
      //       </div>
      //     ),
      //   },
      //   {
      //     key: 22,
      //     icon: null,
      //     label: (

      //       <div
      //         className="h5-index-menu-item"
      //         onClick={() => handleGoMenu("/ecosystem/esdtToken")}
      //       >
      //         <span className="nav-text">EsdtToken</span>
      //       </div>
      //     ),
      //   },
      //   {
      //     key: 23,
      //     icon: null,
      //     label: (
      //       <div
      //         className="h5-index-menu-item"
      //         onClick={() => handleGoMenu("/ecosystem/ltToken")}
      //       >
      //         <span className="nav-text">LtToken</span>
      //       </div>
      //     ),
      //   },
      // ],
    },
    {
      key: 3,
      icon: null,
      children: [],
      label: (
        <div
          className="h5-index-menu-item"
          onClick={() => handleGoMenu("/community")}
        >
          <span className="nav-text">Community</span>
        </div>
      ),
    },
    {
      key: 4,
      icon: null,
      children: [],

      label: (
        <div
          className="h5-index-menu-item"
          onClick={() => handleGoMenu("/faq")}
        >
          <span className="nav-text">FAQ</span>
        </div>
      ),
    },
  ];

  const languageItems = [
    {
      key: "1",
      label: (
        <div
          className={
            locale == "zh-CN" ? "language-item-selected" : "language-item"
          }
          onClick={() => {
            handleSetlanguage("zh-CN");
          }}
        >
          <img src={zhIcon} className="language-icon" />
          <span className="language-text">{t("home.ch")}</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className={
            locale == "en-US" ? "language-item-selected" : "language-item"
          }
          onClick={() => {
            handleSetlanguage("en-US");
          }}
        >
          <img src={EnIcon} className="language-icon" />
          <span className="language-text">{t("home.en")}</span>
        </div>
      ),
    },
  ];

  const handleSetlanguage = (lang) => {
    console.log(lang);
    sessionStorage.setItem("langType", lang);
    i18n.changeLanguage(i18n.language == "en-US" ? "zh-CN" : "en-US");

  };
  const handleGoMenu = (path) => {
    history.push(path);
    handleColseMenu();
  };

  const handleChangeSubMenuOpenStatus = () => {
    setSubMenuOpenStatus(!subMenuOpenStatus);
  };
  return (
    <div className="h5-index-menu">
      <div className="menu-box">
        <div className="menu-item" onClick={() => handleGoMenu("/home")}>
          <div className="name-box">
            <img className="item-icon" src={MenuHomeIcon}></img>
            <div className="item-name">Home</div>
          </div>
          <div className="arrow-box">
            <img src={rightWhiteIcon} />
          </div>
        </div>
        <div
          className="menu-sub-menu"
          onClick={() => {
            handleChangeSubMenuOpenStatus();
          }}
        >
          <div className="menu-item">
            <div className="name-box">
              <img className="item-icon" src={MenuEcosystemIcon}></img>
              <div className="item-name">Ecosystem</div>
            </div>
            <div className="arrow-box">
              <img
                src={yellowDownIcon}
                className={subMenuOpenStatus ? "normal" : "right"}
              />
            </div>
          </div>
          {subMenuOpenStatus ? (
            <div className="child-menu-group">
              <div
                className="child-menu-item"
                onClick={() => handleGoMenu("/ecosystem/index")}
              >
                <div className="name-box">
                  <img className="item-icon" src={EcologyIcon}></img>
                  <div className="item-name">Ecosystem</div>
                </div>
                <div className="arrow-box">
                  <img src={rightWhiteIcon} />
                </div>
              </div>
              <div
                className="child-menu-item"
                onClick={() => handleGoMenu("/ecosystem/esdtToken")}
              >
                <div className="name-box">
                  <img className="item-icon" src={EsdtTokenIcon}></img>
                  <div className="item-name">EsdtToken</div>
                </div>
                <div className="arrow-box">
                  <img src={rightWhiteIcon} />
                </div>
              </div>
              <div
                className="child-menu-item"
                onClick={() => handleGoMenu("/ecosystem/ltToken")}
              >
                <div className="name-box">
                  <img className="item-icon" src={LtTokenIcon}></img>
                  <div className="item-name">LtToken</div>
                </div>
                <div className="arrow-box">
                  <img src={rightWhiteIcon} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="menu-item" onClick={() => handleGoMenu("/community")}>
          <div className="name-box">
            <img className="item-icon" src={MenuCommunityIcon}></img>
            <div className="item-name">Community</div>
          </div>
          <div className="arrow-box">
            <img src={rightWhiteIcon} />
          </div>
        </div>
        <div className="menu-item" onClick={() => handleGoMenu("/faq")}>
          <div className="name-box">
            <img className="item-icon" src={MenuFaqIcon}></img>
            <div className="item-name">FAQ</div>
          </div>
          <div className="arrow-box">
            <img src={rightWhiteIcon} />
          </div>
        </div>
        {/* <Menu mode={"vertical"} items={items} triggerSubMenuAction={'click'}/> */}
      </div>
      <div className="language-row">
        <div className="left">
          {navigator.language == "zh-CN" ? (
            <img src={EnIcon} className="language-icon"></img>
          ) : (
            <img src={zhIcon} className="language-icon"></img>
          )}
          <span>{t("home.language")}</span>
        </div>

        <Dropdown menu={{ items: languageItems }}>
          <div className="right">
            <div className="language-name">{sessionStorage.getItem("langType") == 'zh-CN' ? t('home.ch'):t('home.en')}</div>

            <img src={rightWhiteIcon} className="right-icon" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
});
