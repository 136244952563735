import React, { useRef,useState } from "react";
import { useTranslation } from "react-i18next";
import DappMenuIcon from "../assets/images/h5/header/dappMenu.png";
import HomeMenuIcon from "../assets/images/h5/header/homeMenu.png";
import AccountInfoIcon from "../assets/images//h5/header/accountInfo.png";
import LogoImg from "../assets/images/home/img4.png";
import { Popup } from "antd-mobile";
import { H5DappMenu } from "../components/H5DappMenu";
import { H5IndexMenu } from "../components/H5IndexMenu";
import PupCloseIcon from "../assets/images/h5/header/pupclose.png";

import {ConnectWalletModal} from "../components/ConnectWalletModal";

export default function MobileHeader() {
  const { t } = useTranslation();
  const connectWalletModalRef = useRef();

  const [connectWalletVisible, setConnectWalletVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [menuType, setMenuType] = useState(null);

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const handleOpenDappMenu = () => {
    setMenuType("dapp");
    setPopupVisible(true);
  };

  const closeDappMenu = () => {
    setPopupVisible(false);
  };

  const handleOpenIndexMenu = () => {
    setMenuType("index");
    setPopupVisible(true);
  };

  const handleCloseConnectWallet =() => {
    setConnectWalletVisible(false)
  }
  return (
    <div className="h5-page-header">
      <div className="logo-box">
        <div className="logo">
          <img src={LogoImg}></img>
        </div>
        <div className="logo-text">EASEDEX</div>
      </div>
      <div className="header-actions">
        <div className="wallet-action">
          <span
            onClick={() => {
              setConnectWalletVisible(true);
            }}
          >
            + Connect Wallet
          </span>

          {connectWalletVisible ? (
            <ConnectWalletModal
              ref={connectWalletModalRef}
              closeModal={() => {
                handleCloseConnectWallet();
              }}
            />
          ) : null}

          <Popup
            visible={popupVisible}
            onMaskClick={() => {
              hidePopup();
            }}
            onClose={() => {
              hidePopup();
            }}
            position="top"
          >
            <div className="h5-pup-box">
              <div className="header-row">
                <div className="logo-box">
                  <div className="logo">
                    <img src={LogoImg}></img>
                  </div>
                  <div className="logo-text">EASEDEX</div>
                </div>
                <div className="close-action" onClick={hidePopup}>
                  <img src={PupCloseIcon}></img>
                </div>
              </div>
              {menuType == "dapp" ? (
                <H5DappMenu handleColseMenu={closeDappMenu} />
              ) : (
                <H5IndexMenu handleColseMenu={closeDappMenu} />
              )}
            </div>
          </Popup>
        </div>
        <img
          className="dapp-menu"
          src={DappMenuIcon}
          onClick={() => handleOpenDappMenu()}
        ></img>
        <img
          className="home-menu"
          src={HomeMenuIcon}
          onClick={() => handleOpenIndexMenu()}
        ></img>
      </div>
    </div>
  );
}
