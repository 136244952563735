// 获取app安装二维码
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const InstallAppQRCode = React.forwardRef(({handleClickContinue}, ref) => {
    const { t } = useTranslation();

    const clickContinue = () => {

        handleClickContinue()
     }
    return (
        <div className="intall-app-qr-code-comp">
            <div className="code-box"></div>
            <Button className="continue-btn" onClick={() => {clickContinue()}}>{t("selectWallet.continue")}</Button>
        </div>
    )
})