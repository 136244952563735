import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { GetApp } from "./GetApp";
import { InstallAppQRCode } from "./InstallAppQRCode";
import { GetStart } from "./GetStart";
import { Connecting } from "./Connecting";
import LightBackIcon from '../assets/images/dapp/selectAppLightBackIcon.svg';
import DarkBackIcon from '../assets/images/dapp/selectAppDarkBackIcon.svg';
export const SelectWalletScan = React.forwardRef(
  ({ handleBackList, handleConnect,handleChangeWallet }, ref) => {
    const { t } = useTranslation();

    const [viewType, setViewType] = useState("scan"); // scan, getApp , installAppQRCode，Connecting

    // 点击返回
    const handleBack = () => {
      handleBackList();
    };

    // 点击get app

    const handleClickGetApp = () => {
      console.log("handleClickGetApp");
      setViewType("getApp");
    };

    const handleShowGetAppCode = () => {
      setViewType("installAppQRCode");
    };

  

    return (
      <div className="select-wallet-scan-comp">
        {viewType != "connecting" ? (
          <div className="header-row">
            <div className="back-action" onClick={() => handleBack()}>
              <img src={LightBackIcon} className="light-back-icon"></img>
              <img src={DarkBackIcon} className="dark-back-icon"></img>
            </div>
            <div className="title-text">{ viewType == "getApp" ? t("selectWallet.getMateMask") :  viewType == "installAppQRCode" ? t("selectWallet.installMask") : t("selectWallet.scanTitle")}</div>
            <div className="right"></div>
          </div>
        ) : null}

        {viewType == "scan" ? (
          <div className="scan-body">
            <div className="body-box"></div>
            <div className="agree-box">
              <Checkbox></Checkbox>
              <div className="agree-text">
                {t("connectWallet.agreeText")}
                <span className="link-text">{t("connectWallet.terms")}</span>
                {t("connectWallet.and")}
                <span className="link-text">{t("connectWallet.privacy")}</span>
              </div>
            </div>
            <div className="action-box">
              <Button className="connect-btn" onClick={() => handleConnect()}>
                {t("connectWallet.connect")}
              </Button>
              <Button
                className="get-app-btn"
                onClick={() => {
                  setViewType("getApp");
                }}
              >
                {t("selectWallet.getAppBtn")}
              </Button>
            </div>
            <div className="more-box"></div>
          </div>
        ) : viewType == "getApp" ? (
          <GetApp handleGetAppCode={() => handleShowGetAppCode()} />
        ) : viewType == "installAppQRCode" ? (
          <InstallAppQRCode
            handleClickContinue={() => setViewType("installStart")}
          />
        ) : viewType == "installStart" ? (
          <GetStart handleClickConnect={() => setViewType("connecting")} />
        ) : viewType == "connecting" ? (
          <Connecting handleSelectWallet={handleChangeWallet}/>
        ) : null}
      </div>
    );
  }
);
