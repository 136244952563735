import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import installIcon1 from "../assets/images/installIcon1.svg";
import DarkArrowIcon from "../assets/images/dapp/dark_arrow.svg";
import LightArrowIcon from "../assets/images/dapp/light_arrow.svg";
import detectEthereumProvider from "@metamask/detect-provider";

import WEB3 from "web3";
import {
    LoadingOutlined,
    RightOutlined
  } from '@ant-design/icons'
export const Connecting = React.forwardRef(({handleSelectWallet,closeModal}, ref) => {
  const { t } = useTranslation();
  const address = useRef();
  const handleClickChange =() => {
    handleSelectWallet()
  }
  const startApp = async(provider)=> {
    // If the provider returned by detectEthereumProvider isn't the same as window.ethereum, something
    // is overwriting it – perhaps another wallet. See the "Connect to MetaMask" guide for detecting
    // and connecting to multiple wallets.
    if (provider !== window.ethereum) {
        console.error("Do you have multiple wallets installed?");
    }
    const web3 = new WEB3(window.ethereum);
    try {
      // Request account access if needed
      await window.ethereum.enable();
      // Acccounts now exposed
      //resolve(web3);
    } catch (error) {
      
    }
    let accounts = await web3.eth.getAccounts();
    
    if(accounts[0]){
      const address = accounts[0].toLowerCase();
      const addr = address.substring(0,4) + '...' + address.substring(address.length-6);
      sessionStorage.setItem("addr",addr);
      sessionStorage.setItem("address",address);
      closeModal()
    }
    console.log(accounts[0]);
    // Access the decentralized web!
}

  const connectings= async()=>{
    
    const provider = await detectEthereumProvider();
    if (provider) {
      // From now on, this should always be true:
      // provider === window.ethereum
      startApp(provider); // Initialize your dapp.
    } else {
        console.log("Please install MetaMask!");
    }
      
   
  }
  setTimeout(function(){
    connectings();
  },3000);

  return (
    <div className="connecting-box">
      <div className="content">
        <div className="icon-box">
            <img src={installIcon1}></img>
        </div>
        <div className="status-text">{t("connectWallet.connectTitle")}</div>
        <div className="desc-text">{t("connectWallet.connectDesc")}</div>
        <div className="status-icon">
        <LoadingOutlined />
        </div>
        <div className="retry-btn" onClick={() => connectings()}>{t("connectWallet.retry")}</div>
      </div>
      <div className="change-wallet-box" onClick={() => handleClickChange()}>{t("connectWallet.changeWallet")}<img src={DarkArrowIcon}  className="right-arrow"/> <img src={LightArrowIcon}  className="light-arrow"/></div>

    </div>
  );
});
