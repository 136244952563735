/* eslint-disable react/display-name */

import React from 'react';
import { Spin } from 'antd';

export const LoadingComponent = (props) => {
    const { loading, tip } = props;
    const style = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: loading ? 'flex' : 'none',
        // display:(loading ? 'flex':'flex'),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.5)',
        zIndex: 10000,
    };
    return (
        <div style={style}>
            <Spin spinning={loading} size="large" tip={tip} />
        </div>
    );
};
