import React, { useState, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Dropdown } from "antd";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import WalletConnectIcon from "../assets/images/walletConnectIcon.svg";
import enIcon from "../assets/images/en.png";
import zhIcon from "../assets/images/zh.png";
import i18n from "i18next";
import cnIcon from "../assets/images/dapp/cn.svg";
import usIcon from "../assets/images/dapp/us.svg";
import changeIcon from "../assets/images/dapp/change.svg";
import shareIcon from "../assets/images/dapp/share.svg";
import disconnectIcon from "../assets/images/dapp/disconnect.svg";

export const AccountCornerInfo = React.forwardRef(
  ({ handleSelectWallet, closeModal }, ref) => {
    const { t } = useTranslation();

    const [openStatus, setOpenStatus] = useState(false);
    const [deviceType, setDeviceType] = useState(
      sessionStorage.getItem("deviceType")
    );
    const [locale, setLocale] = React.useState("");

    const [accountInfo, setAccountInfo] = useState({
      status: "Online",
      connected: true,
      chain: "BSC(Binance Smart Chain)",
      wallet: "MetaMask",
      name: "BSC Wallet 01",
      address: "0xco34c52352vxc43c25d3c443ddx2dsx3dsxd3c453xd7150ba",
      eth: "3.32535577",
      esdt: "22.75382463",
      lt: "22.75382463",
    });
    useImperativeHandle(ref, () => ({
      open: () => {
        return handleOpen();
      },
    }));

    const handleOpen = () => {
      setOpenStatus(true);
    };

    const close = () => {
      setOpenStatus(false);
    };

    const languageItems = [
      {
        key: "2",
        label: (
          <div
            className={
              locale == "en-US" ? "language-item-selected" : "language-item"
            }
            onClick={() => {
              handleSetlanguage("en-US");
            }}
          >
            <img src={enIcon} className="language-icon" />
            <span className="language-text">{t("home.en")}</span>
          </div>
        ),
      },
      {
        key: "1",
        label: (
          <div
            className={
              locale == "zh-CN" ? "language-item-selected" : "language-item"
            }
            onClick={() => {
              handleSetlanguage("zh-CN");
            }}
          >
            <img src={zhIcon} className="language-icon" />
            <span className="language-text">{t("home.ch")}</span>
          </div>
        ),
      },
    ];

    const handleSetlanguage = (lang) => {
      sessionStorage.setItem("langType", lang);
      i18n.changeLanguage(lang);
      setLocale(lang);
    };
    return (
      <Modal
        className="account-corner-info-comp"
        style={{
          top: 84,
        }}
        open={openStatus}
        footer={null}
        title={null}
        closeIcon={null}
      >
        <div className="account-corner-info-body">
          <div className="top-row">
            <div className="left">
              <UserOutlined className="title-corner-icon" />
              {t("connectWallet.account")}
            </div>
            <CloseOutlined className="close-icon" onClick={() => close()} />
          </div>

          <div className="status-row">
            <div className="left">
              <div
                className={
                  accountInfo.connected
                    ? "status-icon success-status"
                    : "status-icon error-status"
                }
              ></div>
              <div className="connect-status">
                {accountInfo.connected
                  ? t("connectWallet.connected")
                  : t("connectWallet.disconnected")}
              </div>
              <div className="online-status">{accountInfo.status}</div>
            </div>
            <div className="right">
              <div className="change-box">
                <div className="change-text">{t("connectWallet.change")}</div>
                <img src={changeIcon} className="change-icon" />
              </div>
            </div>
          </div>
          <div className="info-body">
            <div className="info-item">
              <div className="item-label">{t("connectWallet.chain")}:</div>
              <div className="item-value">{accountInfo.chain}</div>
            </div>
            <div className="info-item">
              <div className="item-label">{t("connectWallet.wallet")}:</div>
              <div className="item-value has-control">
                <img src={WalletConnectIcon} className="wallet-icon" />
                {accountInfo.wallet}
              </div>
            </div>
            <div className="info-item">
              <div className="item-label">{t("connectWallet.name")}:</div>
              <div className="item-value">{accountInfo.name}</div>
            </div>
            <div className="info-item">
              <div className="item-label">{t("connectWallet.address")}:</div>
              <div className="item-value">{accountInfo.address}</div>
            </div>
            <div className="center-line"></div>
            <div className="info-item">
              <div className="item-label">{t("connectWallet.ETH")}:</div>
              <div className="item-value">{accountInfo.eth}</div>
            </div>
            <div className="info-item">
              <div className="item-label">{t("connectWallet.ESDT")}:</div>
              <div className="item-value">{accountInfo.esdt}</div>
            </div>
            <div className="info-item">
              <div className="item-label">{t("connectWallet.LT")}:</div>
              <div className="item-value">{accountInfo.lt}</div>
            </div>
          </div>
          <div className="footer-row">
            <div className="language-setting">
              <Dropdown
                menu={{ items: languageItems }}
                overlayClassName="pc-language-dropdown"
                placement="bottom"
              >
                <div className="languagt-setting">
                  {locale == "zh-CN" ? (
                    <img src={cnIcon} className="language-icon" />
                  ) : (
                    <img src={usIcon} className="language-icon" />
                  )}

                  {locale == "zh-CN" ? t("home.ch") : t("home.en")}
                </div>
              </Dropdown>
            </div>

            <div className="share-box">
              <img src={shareIcon} className="share-icon" />
              {t("connectWallet.viewOnBscscan")}
            </div>

            <div className="disconnect-box">
              <img src={disconnectIcon} className="disconnect-icon" />
              {t("connectWallet.disconnect")}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);
