import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Modal } from "antd";
import CloseIcon from "../assets/images/close.svg";
import ErrorIcon from '../assets/images/error.svg'
import { useTranslation } from "react-i18next";

export const ConnectNetError = React.forwardRef(({ closeModal }, ref) => {
  const [openStatus, setOpenStatus] = useState(false);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    open: () => {
      return handleOpen();
    },
  }));

  const handleOpen =() => {
    setOpenStatus(true);
    
  }

  const handleClickClose = () => {
    setOpenStatus(false);
  };
  return (
    <Modal
      className="connect-net-error-modal"
      open={openStatus}
      footer={null}
      title={null}
      closeIcon={null}
      width={342}
      style={{
        top: 84,
      }}
    >
      <div className="connect-net-error-comp">
        <div className="title-row">
          <img
            src={CloseIcon}
            className="close-icon"
            onClick={() => handleClickClose()}
          />
        </div>
        <div className="body">
          <img className="notice-icon" src={ErrorIcon}></img>
          <div className="info">
            <div className="title">{t("home.netErrorTitle")}</div>
            <div className="desc">{t("home.netErrorDesc")}</div>
          </div>
        </div>
        <div className="footer-actions">
            <div className="cancel-btn">{t("home.disconnect")}</div>
            <div className="confirm-btn">{t("home.switch")}</div>

        </div>
      </div>
    </Modal>
  );
});
