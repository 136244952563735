// 获取app 主界面，移动端，电脑端

import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import walletConnectIcon from "../assets/images/walletConnectIcon.svg";
import IeIcon from "../assets/images/dapp/iE.svg";


export const GetApp = React.forwardRef(({handleGetAppCode}, ref) => {
  const { t } = useTranslation();


  // 点击getApp
  const  handleGetApp = () => {
    handleGetAppCode()
  }
    return (
        <div className="get-app-comp">
            <div className="box-item top-box">
                <div className="icon-box">
                <img src={IeIcon}></img>

                </div>
                <div className="info-box">
                    <div className="title">{t('selectWallet.edge')}</div>
                    <div className="desc">{t('selectWallet.edgeDesc')}</div>
                    <div className="btn-box">
                        <Button className="add-btn edge-btn"  onClick={() => handleGetApp()}>{t("selectWallet.addEdge")}</Button>
                    </div>
                </div>

            </div>
            <div className="box-item  bottom-box">
            <div className="icon-box">

                <img src={walletConnectIcon}></img>
            </div>
                <div className="info-box">
                    <div className="title">{t('selectWallet.mobile')}</div>
                    <div className="desc">{t('selectWallet.mobileDesc')}</div>
                    <div className="btn-box">
                        <Button className="add-btn app-btn" onClick={() => handleGetApp()}>{t("selectWallet.getTheApp")}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
})