import React, { useState, useEffect, useRef } from "react";
import { Checkbox, Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import WalletConnectIcon from "../assets/images/walletConnectIcon.svg";
import DarkArrowIcon from "../assets/images/dapp/dark_arrow.svg";
import LightArrowIcon from "../assets/images/dapp/light_arrow.svg";

import useGlobal from "../utils/globalHooks";
export const ConnectWallet = React.forwardRef(
  ({ handleSelectWallet, handleClose, connect }, ref) => {
    const { t } = useTranslation();
    const [deviceType, setDeviceType] = useState(
      sessionStorage.getItem("deviceType")
    );
    const [globalState, setGlobalState] = useGlobal();
    const [num, setNum] = useState(0);

    const [check, setCheck] = useState(false);
    // 点击change wallet
    const handleClickChange = () => {
      handleSelectWallet();
    };

    const close = () => {
      handleClose();
    };
    // const SessionStorageListener = () => {
    //   const onStorage = useRef(() => {});
    //  console.log(111111);
    //   useEffect(() => {
    //     onStorage.current = () => {
    //       console.log('Session storage changed!');
    //        这里可以根据需要进行相应的处理
    //     };

    //     window.addEventListener('addr', onStorage.current);

    //   }, []);

    //   return null;
    // };
    const handleChange = () => {
      setCheck(!check);
    };
    const handleConnect = () => {
      // let numVal = num + 1
      // setNum(numVal)

      // setGlobalState({accountInfo:numVal})
      console.log(check);
      if (!check) {
        return;
      }
      const addr = sessionStorage.getItem("addr");
      if (!addr) {
        connect();
      }
    };
    return (
      <div className="connect-wallet-comp">
        <div className="top-row">
          <div className="left">
            {deviceType == "Mobile" ? t("connectWallet.title") : null}
          </div>
          <CloseOutlined className="close-icon" onClick={() => close()} />
        </div>
        {deviceType == "PC" ? (
          <div className="title-row">{t("connectWallet.title")}</div>
        ) : null}
        <div className="center-img">
          <img src={WalletConnectIcon} className="img-dom"></img>

          <div className="text">{t("connectWallet.metaMask")}</div>
        </div>
        <div className="agree-box">
          <Checkbox checked={check} onChange={handleChange}></Checkbox>
          <div className="agree-text">
            {t("connectWallet.agreeText")}
            <span className="link-text">{t("connectWallet.terms")}</span>
            {t("connectWallet.and")}
            <span className="link-text">{t("connectWallet.privacy")}</span>
          </div>
        </div>
        <Button className="connect-btn" onClick={() => handleConnect()}>
          {t("connectWallet.connect")}
        </Button>
        <div className="change-wallet-box" onClick={() => handleClickChange()}>
          {t("connectWallet.changeWallet")}
          <img src={DarkArrowIcon} className="right-arrow" />{" "}
          <img src={LightArrowIcon} className="light-arrow" />
        </div>
      </div>
    );
  }
);
