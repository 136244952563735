import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { ConnectWallet } from "./ConnectWallet";
import { SelectWallet } from "./SelectWallet";
import { Connecting } from "./Connecting";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";

export const ConnectWalletModal = React.forwardRef(({ closeModal }, ref) => {
  const [childType, setChildType] = useState("connectWallet"); // connectWallet, change， connecting
  const connectWalletRef = useRef();
  const selectWalletRef = useRef();
  const { t } = useTranslation();
  const [openStatus, setOpenStatus] = useState(false);
  useImperativeHandle(ref, () => ({
    open: () => {
      return handleOpen();
    },
  }));
  const handleOpen = () => {
    setOpenStatus(true);
  };

  const handleClickClose = () => {
    setOpenStatus(false);
  };

  // 点击connect
  const handleConnectClick = () => {
    setChildType("connecting");
  };

  const handleClose = () => {};

  return (
    <Modal
      className="connect-wallet-modal"
      open={openStatus}
      footer={null}
      title={null}
      closeIcon={null}
      style={{
        top: 84,
      }}
    >
      <div>
        {childType === "connectWallet" ? (
          <ConnectWallet
            ref={connectWalletRef}
            handleSelectWallet={() => {
              setChildType("change");
            }}
            handleClose={() => handleClickClose()}
            connect={() => handleConnectClick()}
          />
        ) : childType === "connecting" ? (
          <div className="select-wallet-comp">
            <div className="header">
              <div className="name">{t("selectWallet.title")}</div>
              <CloseOutlined
                className="close-icon"
                onClick={() => handleClickClose()}
              />
            </div>
            <div className="select-wallet-scan-comp">
              <Connecting
                handleSelectWallet={() => {
                  setChildType("change");
                }}
                closeModal={() => handleClickClose()}
              />
            </div>
          </div>
        ) : (
          <SelectWallet
            ref={selectWalletRef}
            handleClose={() => handleClickClose()}
            handleConnect={() => handleConnectClick()}
          />
        )}
      </div>
    </Modal>
  );
});
