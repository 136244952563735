import React, { useImperativeHandle, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CloseOutlined } from "@ant-design/icons";
import DefaultQrCodeImg from "../assets/images/home/defaultQrCode.png";
import { use } from "i18next";
import { Modal } from "antd";

export const WalletApp = React.forwardRef(({}, ref) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  useImperativeHandle(ref, () => ({
    openModal: () => {
      return handleShow();
    },
    close: () => {
      return handleClose();
    },
  }));

  const handleShow = () => {
    setVisible(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  return (
    <>
      <Modal
        className="wallet-app-modal"
        open={visible}
        maskClosable={true}
        footer={null}
        title={null}
        closeIcon={null}
        mask={false}
        style={{
          top: 84,
        }}
      >
        <div className={"wallet-app-comp"}>
          <div className="header">
            <div className="name">{t("walletApp.name")}</div>
            <CloseOutlined
              className="close-icon"
              onClick={(e) => handleClose(e)}
            />
          </div>
          <div className="qr-code-box">
            <img className="code-img" src={DefaultQrCodeImg}></img>
          </div>
          <div className="tips">{t("walletApp.tips")}</div>
          <div className="info">{t("walletApp.info")}</div>
        </div>
      </Modal>
    </>
  );
});
