export const routerMaps = [
  {
    path: "/home",
    name: "Home",
    component: '/home/index',
  },
  {
    path: "/ecosystem",
    name: "Ecosystem",
    children:[
      {
        path: "/ecosystem/index",
        name: "Ecosystem",
        component: '/ecology/index',
      },
      {
        path: "/ecosystem/esdtToken",
        name: "EsdtToken",
        component: '/esdtToken/index',
    
      },
      {
        path: "/ecosystem/ltToken",
        name: "LtToken",
        component: '/ltToken/index',
    
      },
    ]
  },
  {
    path: "/community",
    name: "Community",
    component: '/community/index',

  },
  {
    path: "/faq",
    name: "FAQ",
    component: '/faq/index',
  },
  {
    path: "/dapp",
    name: "Dapp",
    component: '/dapp/index',
    children:[
      {
        path: "/dapp/indexOverview",
        name: "IndexOverview",
        component: '/dapp/indexOverview/index',
      },
    ]
  },
  
];
