import React, { useState } from "react";
import Loadable from "react-loadable";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { routerMaps } from "./router/index";
import { useEffect } from "react";
import { LoadingComponent } from "./components/LoadingComponent";
import PageHeader from "./layout/pageHeader";
import PageFooter from "./layout/pageFooter";
import MobileHeader from "./layout/mobileHeader";
import MobileFooter from "./layout/mobileFooter";
import "./react-i18next-config.js";

function App() {
  const [routers, setRouters] = useState(null);
  const [deviceType, setDeviceType] = useState("PC");
  const [footerShow, setFooterShow] = useState(true);

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    const userAgentInfo = window.navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgentInfo
      )
    ) {
      import("./assets/style/h5index.less");
      sessionStorage.setItem("deviceType", "Mobile");
      setDeviceType("Mobile");
    } else {
      sessionStorage.setItem("deviceType", "PC");
      import("./assets/style/index.less");
      setDeviceType("PC");
    }
    setRouters(dynamicRouter());
  }, []);

  useEffect(() => {

    if (location.pathname.indexOf("/dapp") > -1 ) {
      setFooterShow(false);      
    } else {
      setFooterShow(true);
    }
  }, [location])


  //初始化路由
  function dynamicRouter() {
    const dynamicImport = (component) =>
      Loadable({
        loader: () => import("./pages" + component),
        loading: LoadingComponent,
      });

    //循环子router
    const getRouters = (subRouters = [], routers = []) => {
      subRouters.map((child) => {
        child.component &&
          routers.push(
            <Route
              key={child.path}
              path={child.path}
              component={dynamicImport(child.component)}
            />
          );
        if (child.children) {
          getRouters(child.children, routers);
        }
      });

      return routers;
    };

    let routerList = getRouters(routerMaps);

    console.log(routerList);
    const defaultRoute = [
      <Route
        key={"/"}
        exact
        strict
        path="/"
        render={() => <Redirect to={routerMaps[0].path} />}
      />,
    ];
    return defaultRoute.concat(routerList);
  }

  return (
      <Router >
        {deviceType === "Mobile" ? <MobileHeader /> : <PageHeader />}
        <div className="app-content">
          <Switch>{routers}</Switch>
        </div>
        {
          footerShow ?
          deviceType === "Mobile" ? <MobileFooter /> : <PageFooter />
          :null
        }
      </Router>
  );
}

export default App;
