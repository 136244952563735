import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Modal } from "antd";
import ErrorIcon from "../assets/images/error.svg";
import { useTranslation } from "react-i18next";

export const PermissionReminder = React.forwardRef(({ closeModal }, ref) => {
  const [openStatus, setOpenStatus] = useState(true);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    open: () => {
      return handleOpen();
    },
  }));

  const handleOpen = () => {
    setOpenStatus(true);
  };

  const handleClickClose = () => {
    setOpenStatus(false);
  };
  return (
    <>
      {openStatus ? (
        <div className="premission-reminder-modal">
          <div className="premission-reminder-comp">
            <div className="body">
              <div className="info">
                <div className="title">{t("home.permissionTitle")}</div>
                <div className="desc">{t("home.permissionDesc")}</div>
              </div>
            </div>
            <div className="footer-actions">
              <div className="cancel-btn" onClick={() => handleClickClose()}>
                {t("home.outPut")}
              </div>
              <div className="confirm-btn" onClick={() => handleClickClose()}>
                {t("home.allowAnalytics")}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
});
