import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Route, Switch } from "react-router-dom";
import zhishugailanIcon from "../assets/images/h5/menu/zhishugailan.png";
import zhishugailanActiveIcon from "../assets/images/h5/menu/zhishugailan_active.png";
import kaishijiaoyiIcon from "../assets/images/h5/menu/kaishijiaoyi.png";
import kaishijiaoyiActiveIcon from "../assets/images/h5/menu/kaishijiaoyi_active.png";
import zhiyawakuangIcon from "../assets/images/h5/menu/zhiyawakuang.png";
import zhiyawakuangActiveIcon from "../assets/images/h5/menu/zhiyawakuang_active.png";
import eswapIcon from "../assets/images/h5/menu/eswap.png";
import eswapActiveIcon from "../assets/images/h5/menu/eswap_active.png";
import qianbaozichanIcon from "../assets/images/h5/menu/qianbaozichan.png";
import qianbaozichanActiveIcon from "../assets/images/h5/menu/qianbaozichan_active.png";
import zhishutiaokongIcon from "../assets/images/h5/menu/zhishutiaokong.png";
import zhishutiaokongActiveIcon from "../assets/images/h5/menu/zhishutiaokong_active.png";
import FooterIcon1 from "../assets/images/h5/menu/footerIcon1.png";
import FooterIcon2 from "../assets/images/h5/menu/footerIcon2.png";
import FooterIcon3 from "../assets/images/h5/menu/footerIcon3.png";
import DappMenuBkg from "../assets/images/h5/menu/dappMenuBk.png";
export const H5DappMenu = React.forwardRef(({ handleColseMenu }, ref) => {
  const { t } = useTranslation();
  const [activeMenu, setActiveMenu] = useState(1);

  const handleChangeMenu = (val) => {
    setActiveMenu(val);
    handleColseMenu()
  }
  return (
    <div className="h5-dapp-menu-comp">
      <div className="menu-group">
          <div className={activeMenu == 1 ? "active-menu" : "normal-menu"} onClick={() => handleChangeMenu(1)}>
            <div className="icon-box">
              {
                activeMenu == 1 ? <img src={zhishugailanActiveIcon}></img> : <img src={zhishugailanIcon}></img>
              }
            </div>
            <div className="name-box">
              <NavLink to="/dapp/indexOverview">
                <span className="nav-text">指数概览</span>
              </NavLink>
            </div>
          </div>
          <div className={activeMenu == 2 ? "active-menu" : "normal-menu"} onClick={() => handleChangeMenu(2)}>
            <div className="icon-box">
              {
                activeMenu == 2 ? <img src={kaishijiaoyiActiveIcon}></img> : <img src={kaishijiaoyiIcon}></img>
              }
            </div>
            <div className="name-box">
              <NavLink to="/dapp/deal">
                <span className="nav-text">开始交易</span>
              </NavLink>
            </div>
          </div>
          <div className={activeMenu == 3 ? "active-menu" : "normal-menu"} onClick={() => handleChangeMenu(3)}>
            <div className="icon-box">
              {
                activeMenu == 3 ? <img src={zhiyawakuangActiveIcon}></img> : <img src={zhiyawakuangIcon}></img>
              }

            </div>
            <div className="name-box">
              <NavLink to="/dapp/indexOverview">
                <span className="nav-text">质押挖矿</span>
              </NavLink>
            </div>
          </div>
          <div className={activeMenu == 4 ? "active-menu" : "normal-menu"} onClick={() => handleChangeMenu(4)}>
            <div className="icon-box">
              {
                activeMenu == 4 ? <img src={eswapActiveIcon}></img> : <img src={eswapIcon}></img>
              }

            </div>
            <div className="name-box">
              <NavLink to="/dapp/indexOverview">
                <span className="nav-text">ESwap</span>
              </NavLink>
            </div>
          </div>
          <div className={activeMenu == 5 ? "active-menu" : "normal-menu"} onClick={() => handleChangeMenu(5)}>
            <div className="icon-box">{
              activeMenu == 5 ? <img src={qianbaozichanActiveIcon}></img> : <img src={qianbaozichanIcon}></img>
            }</div>
            <div className="name-box">
              <NavLink to="/dapp/indexOverview">
                <span className="nav-text">钱包资产</span>
              </NavLink>
            </div>
          </div>
          <div className={activeMenu == 6 ? "active-menu" : "normal-menu"} onClick={() => handleChangeMenu(6)}>
            <div className="icon-box">{
              activeMenu == 6 ? <img src={zhishutiaokongActiveIcon}></img> : <img src={zhishutiaokongIcon}></img>
            }</div>
            <div className="name-box">
              <NavLink to="/dapp/indexRegulation">
                <span className="nav-text">指数调控</span>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="footer-info">
            <div className="left">
              <img className="left-icon-item" src={FooterIcon1}></img>
              <img className="left-icon-item"  src={FooterIcon2}></img>
              <img className="left-icon-item"  src={FooterIcon3}></img>
            </div>
            <div className="right">© 2023 easedex.finance</div>
        </div>
        <img src={DappMenuBkg} className="dapp-menu-bk"></img>
    </div>
  );
});
